.menu__item {
  font-weight: 400;
  font-size: 1.3em;
  padding: 20px 0;
  border-bottom: 1px solid #c3f8ff;
}

.menu__sub-item {
  font-weight: 400;
  font-size: 1em;
  padding: 20px 0;
  color: #fff;
  margin-left: 16px;
}

.menu__burger-menu a, 
.menu__burger-menu a:hover, 
.menu__burger-menu a:visited {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 20px;
  right: 16px;
  top: 20px;
}

.bm-burger-button button {
  outline: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #FFF;
  border-radius: 2px;
  height: 10% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #FFF;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross-button button {
  outline: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #FFF;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #70ccd9;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #70ccd9;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (min-width: 0) and (max-width: 600px) {
  .menu__container {
    display: none;
  }
}