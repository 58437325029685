.about__container {
  margin: 80px 0;
  display: flex;
  justify-content: center;
}

.about__title {
  font-size: 5em;
  margin-bottom: 24px;
  color: #70ccd9;
  font-family: 'Cookie', cursive;
  text-align: center;
}

.about__content-container {
  margin-right: 40px;
  margin-bottom: 0;
  text-align: justify;
}

.about__image-container {
  display: flex;
  justify-content: center;
}

.about__image-container img {
  width: 100%;
}

@media (min-width: 0) and (max-width: 959px) {
  .about__content-container {
    margin-right: 0;
    margin-bottom: 40px;
  }
}