.product__img {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
}

.product__info {
  font-size: 1em;
  margin-left: 40px;
}

.product__info-container a,
.product__info-container a:hover,
.product__info-container a:visited {
  color: #333;
  text-decoration: none;
}

.product__price {
  color: #ef4d65;
}

@media (min-width: 0) and (max-width: 600px) {
  .product__img {
    margin-bottom: 0;
  }

  .product__info {
    margin-left: 0;
    margin-top: 16px;
    margin-bottom: 80px;
  }
}