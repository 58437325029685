.logo__container {
  position: absolute;
  left: 100px;
  top: 0;
  width: 150px;
  height: 170px;
  background-color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, .2);
  border-radius: 0 0 5px 5px;
}

.logo__container img {
  width: 150px;
}

.logo__text {
  display: none;
}

.logo__link {
  text-decoration: none;
}

@media (min-width: 0) and (max-width: 1024px) {
  
  .logo__container {
    display: none;
  }

  .logo__text {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: 'Cookie', cursive;
    color: #FFF;
    margin-left: 7%;
    font-size: 2.5rem;
  }

  .logo__text img {
    width: 35px;
    margin-right: 16px;
  }

  .logo__text a,
  .logo__text a:hover,
  .logo__text a:visited {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}