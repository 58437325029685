.contact__image-container img {
  width: 100%;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
  margin: 80px 0;
}

.contact__title {
  font-size: 5em;
  color: #70ccd9;
  margin: 60px 0 30px;
  font-family: 'Cookie', cursive;
  text-align: center;
}

.wrapper {
  display: grid;
  grid-template-columns: 40px 360px;
  grid-gap: 10px;
  text-align: center;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
}

.box.icon {
  justify-content: center;
}

.box img {
  width: 40px;
  box-shadow: none;
}

.box.text,
.box a {
  color: #ef4d65;
  text-decoration: none;
}

.box.text {
  font-size: 1em;
}

@media (min-width: 0) and (max-width: 959px) {
  .box.text {
    margin-bottom: 40px;
  }

  .contact__image-container img {
    margin-top: 40px;
  }
}
