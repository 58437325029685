.body__container {
  height: 800px;
  background-image: url(../../media/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  color: #444;
}

.body__title_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 60vw;
  color: #444;
}

.body__title {
  font-size: 15vh;
  font-family: 'Cookie', cursive;
  line-height: 0.7;
}

.body__subtitle {
  font-size: 2em;
  margin-top: 40px;
  font-weight: 300;
}

.body__tagline {
  font-size: 2.5em;
  font-weight: 300;
}