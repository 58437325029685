.footer__container {
  background-color: #70ccd9;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer__container a {
  margin: 0 24px;
}

.footer__container img {
  width: 40px;
}

.footer__copyright {
  color: white;
  margin-top: 16px;
}
