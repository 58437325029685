.products__section {
  font-size: 5em;
  font-family: 'Cookie', cursive;
  color: #70ccd9;
  margin: 40px 0;
  text-align: center;
}

@media (min-width: 0) and (max-width: 600px) {
  .products__section {
    text-align: center;
  }
}